<template>
  <BCard
    v-if="'All Time' in citedData && citedData['All Time'] && citedData['All Time'].length > 0"
    header-tag="h2"
    header-bg-variant="ams-gray"
    header-border-variant="ams-header-orange"
    header-text-variant="black"
    header-class="border-bottom highlight-border"
    align="left"
    class="shadow mt-3"
  >
    <template slot="header">
      Citations
    </template>
    <BRow>
      <BCol>
        <BFormGroup
          class="form-row indent-xs"
          role="group"
          aria-label="Citation Year Select"
          inline
        >
          <label
            id="cite-year-label"
            class="col-form-label"
            label-for="year-select"
          >
            <span class="d-none d-sm-inline-block">
              Citation Year
            </span>
            <span class="d-inline-block d-sm-none">
              Cit. Yr.
            </span>
          </label>
          <BFormSelect
            id="year-select"
            v-model="citedYear"
            :options="yearOptions"
            :disabled="citedSelectDisabled"
            aria-labelledby="cite-year-label"
          />
          <BButton
            class="btn-ams-blue-dark"
            :disabled="citedSelectDisabled"
            @click="getCitations"
          >
            {{ citedSelectText }}
          </BButton>
        </BFormGroup>
      </BCol>
    </BRow>

    <BRow>
      <BCol
        class="p0"
      >
        <BTabs
          ref="citations-tabs"
          style="width:100%;"
          class="citations"
          :class="{ loading : citedSelectDisabled}"
        >
          <BTab lazy>
            <template slot="title">
              <span title="Citations Graph">
                <span class="d-none d-md-inline-block d-lg-inline-block d-xl-inline-block">
                  Graph&nbsp;
                </span>
                <FontAwesomeIcon
                  role="presentation"
                  icon="chart-bar"
                  aria-hidden="true"
                />
              </span>
            </template>
            <BFormCheckbox
              v-if="isRefList && internal"
              v-model="usePercent"
              class="indent-xs"
            >
              Display Self Citation %
            </BFormCheckbox>
            <Graph
              :data1="citeGraph"
              :range="citeRange"
            />
            <!-- <MRSliderComponent
              v-range="yearSlider.range"
              :min="yearSlider.min"
              :max="yearSlider.max"
              :steps="7"
              :subdivision-total="4"
            /> -->
            <span title="Year range selector">
              <!-- In the 2023 update, this became very laggy and unresponsive. -->
              <!--
              <MRSliderComponent
                v-range="yearSlider.range"
                :min="yearSlider.min"
                :max="yearSlider.max"
                :steps="7"
              /> -->
              <!-- Same functionality was replaced -->
              <BRow>
                <BCol>
                  <span class="float-right">
                    <label for="startYear">Starting Year&nbsp;</label>
                    <BFormSelect
                      name="startYear"
                      id="startYear"
                      style="width: auto;"
                      v-model="yearSlider.range[0]"
                      :options="yearSlider.years"
                    />
                  </span>
                </BCol>
                <BCol>
                  <span class="float-left">
                    <label for="endingYear">Ending Year&nbsp;</label>
                    <BFormSelect
                      name="endingYear"
                      id="endingYear"
                      style="width: auto;"
                      v-model="yearSlider.range[1]"
                      :options="yearSlider.years"
                    />
                  </span>
                </BCol>
              </BRow>
            </span>
          </BTab>
          <BTab>
            <template slot="title">
              <span title="Citations Table">
                <span class="d-none d-md-inline-block d-lg-inline-block d-xl-inline-block">
                  Table&nbsp;
                </span>
                <FontAwesomeIcon
                  role="presentation"
                  icon="columns"
                  aria-hidden="true"
                />
              </span>
            </template>
            <BFormCheckbox
              v-if="isRefList && internal"
              v-model="usePercent"
            >
              Display Self Citation %
            </BFormCheckbox>
            <h3
              class="table-title"
              v-html="citeGraphChangeableTitle"
            />
            <Msntable :rows="citeTable" />
          </BTab>

          <BTab lazy>
            <template slot="title">
              <span title="Top Citing Publications Graph">
                <span class="d-none d-sm-inline-block">
                  Top Citers
                </span>
                <span class="d-inline-block d-sm-none hide-xxs">
                  Cit.
                </span>
                <span class="d-none d-lg-inline-block">
                  &nbsp;Graph
                </span>&nbsp;
                <FontAwesomeIcon
                  role="presentation"
                  icon="chart-bar"
                  aria-hidden="true"
                />
              </span>
            </template>
            <Graph
              :data1="citeTopGraph"
            />
          </BTab>
          <BTab>
            <template slot="title">
              <span title="Top Citing Publications Table">
                <span class="d-none d-sm-inline-block">
                  Top Citers
                </span>
                <span class="d-inline-block d-sm-none hide-xxs">
                  Cit.
                </span>
                <span class="d-none d-lg-inline-block">
                  &nbsp;Table
                </span>&nbsp;
                <FontAwesomeIcon
                  role="presentation"
                  icon="columns"
                  aria-hidden="true"
                />
              </span>
            </template>
            <h3 class="table-title">
              Top Citing Publications
            </h3>
            <Msntable
              :rows="citeTopTable"
              :header="true"
            />
          </BTab>
          <BTab>
            <template slot="title">
              <span title="Description">
                <span class="d-none d-md-inline-block">
                  Description&nbsp;
                </span>
                <FontAwesomeIcon
                  role="presentation"
                  icon="info-circle"
                  aria-hidden="true"
                />
              </span>
            </template>
            <div class="indent-xs">
              <br>
              <ul>
                <li>Citations to this {{ serialType }} in the selected year. Broken down by years that the cited papers were published in.</li>
                <li>Self Citation refers to instances in which the {{ serialType }} cites another paper in the same {{ serialType }}.</li>
                <li>Top Citing Publications does not list all of the serials that cited the selected {{ serialType }}.</li>
                <li>
                  Citations in the MR Database come from <a
                    href="/mathscinet/mrcit/journal_list.html"
                    title="View All Reference List Journals and Series"
                  >
                    Reference List Journals and Series
                  </a>
                </li>
              </ul>
              <br>
            </div>
          </BTab>
        </BTabs>
      </BCol>
    </BRow>
  </BCard>
</template>
<script>
import Vue from 'vue'
import { formatName, formatNumber } from '@/utils'
// import { MRSliderComponent } from 'mr-vue-components'
import { JournalAPI, SeriesAPI } from '@/js-api'

export default {
  name: 'ProfileCitationsComponent',
  components: {
    Graph: () => import(/* webpackChunkName: "journal_graph" */ '@/components/journal/JournalGraph.vue'),
    Msntable: () => import(/* webpackChunkName: "journal_msntable" */ '@/components/journal/MsnTable.vue'),
    // MRSliderComponent,
  },
  // title: 'ProfileCitations',
  props: {
    groupId: {
      type: Number,
      required: true,
    },
    seriesId: {
      type: Number,
      required: true,
    },
    abbr: {
      type: String,
      required: true,
    },
    isRefList: {
      type: Boolean,
      required: false,
      default: false,
    },
    internal: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      citedYear: 'All Time', // The dropdown selector
      citedYearData: 'All Time', // The cited year the data is from. Used so table titles don't change before the Update button is pressed
      citedSelectText: 'Update',
      citedSelectDisabled: false,
      citeRange: [1990],
      citedData: {
      },
      citedTopData: {
      },
      citeGraph: {
        changeable: {
          // title: 'Citations made to ' + this.serialType + ' over ' + this.citedYear,
          x: [],
          y: [
            [],
            [],
          ],
          axis: {
            x: 'Cited Paper Publication Year',
            y: 'Citations',
          },
          label: [
            'Citations by Year',
            'Self Citations',
          ],
        },
        id: 'cite-graph',
        type: 'bar',
      },
      citeTopGraph: {
        changeable: {
          title: 'Top Citing Publications',
          x: [],
          y: [],
          axis: {
            x: '',
            y: 'Citations',
          },
        },
        id: 'cite-top-graph',
        type: 'bar',
      },
      citeTable: [
        ['Loading...', 'Loading...'],
      ],
      citeTopTable: [
        ['Publication', 'Citations'],
      ],
      yearSlider: {
        defaultYear: 1990,
        minRange: 4,
        min: -1,
        max: -1,
        range: [],
        years: [],
      },
      usePercent: false,

      pubUrl: '/mathscinet/search/publications.html?',
      pubQueryString: {
        series: 'pg4=SE&s4=',
        group: 'groupid=',
        issue: 'pg1=ISSI&s1=',
        cited: 'caller=mrcit',
      },
    }
  },
  computed: {
    isJournal: function() {
      return ((this.jourId && this.jourId > -1) || (this.groupId && this.groupId > -1))
    },
    isSeries: function() {
      return (this.seriesId && this.seriesId > -1)
    },
    serialType: function() {
      return this.isJournal ? 'journal' : 'series'
    },
    latestYear: function() {
      const currentDate = new Date()
      currentDate.setMonth(currentDate.getMonth() - 18)
      return currentDate.getFullYear()
    },
    yearOptions: function() {
      const years = ['All Time']
      for (let i = 0; i < 20; i++) {
        years.push(this.latestYear - i)
      }
      return years
    },
    citeGraphChangeableTitle: function() {
      return 'Citations made to ' + this.serialType + ' over ' + this.citedYearData
    },

    // citeTable and citeTopTable isn't computed here because it would not be able to wait until the Update button was pressed
  },
  watch: {
    usePercent: function() {
      // this.redrawCitations(value)
      this.updateCiteGraph()
      this.updateCiteTable()
    },
    'yearSlider.range': function() {
      // this.citeRange = this.yearSlider.range
      Vue.set(this.citeRange, 0, this.yearSlider.range[0])
      Vue.set(this.citeRange, 1, this.yearSlider.range[1])
    },
  },
  mounted: function() {
    this.getCitations()
  },
  methods: {
    getCitations: function() {
      // called by the Update button
      this.toggleCitationLock()
      this.getCite()
      this.getCiteTop()
    },
    setCiteRange: function() {
      // Set min/max values of the slider
      const defaultMinYear = this.yearSlider.defaultYear
      let defaultMaxYear = this.latestYear
      if (typeof this.citedYear === 'number') {
        defaultMaxYear = Math.min(this.latestYear, this.citedYear)
      }

      const citedInYear = this.citedData[this.citedYear]

      // Handle cases where the slider range was maxed before, keeping it maxed
      if (this.yearSlider.max === this.yearSlider.range[1]) {
        if (citedInYear.length > 0) {
          Vue.set(this.yearSlider.range, 1, citedInYear[citedInYear.length - 1].year)
        } else {
          Vue.set(this.yearSlider.range, 1, defaultMaxYear)
        }
      }

      if (citedInYear && citedInYear.length > 0) {
        this.yearSlider.max = Math.max(citedInYear[citedInYear.length - 1].year, defaultMaxYear)
        this.yearSlider.min = Math.min(this.yearSlider.defaultYear, citedInYear[0].year)
      } else {
        this.yearSlider.max = defaultMaxYear
        this.yearSlider.min = defaultMinYear
      }

      // Set the selected year range if not defined
      if (this.yearSlider.range.length === 0) {
        this.yearSlider.range[0] = this.yearSlider.defaultYear
        Vue.set(this.yearSlider.range, 1, this.yearSlider.max)
      }

      // Adjust selected year range to fit inside the min/max values
      if (this.yearSlider.min > this.citeRange[0]) {
        Vue.set(this.yearSlider.range, 0, this.yearSlider.min)
      }
      if (this.yearSlider.max < this.citeRange[0]) {
        Vue.set(this.yearSlider.range, 0, this.yearSlider.max - this.yearSlider.minRange)
      }
      if (this.yearSlider.max < this.citeRange[1]) {
        Vue.set(this.yearSlider.range, 1, this.yearSlider.max)
      }
      this.yearSlider.years = Array(this.yearSlider.max - this.yearSlider.min + 1).fill(0).map((x, index) => this.yearSlider.max - index)
    },
    toggleCitationLock: function() {
      this.citedSelectDisabled = !this.citedSelectDisabled
      this.citedSelectText = this.citedSelectDisabled ? 'Loading...' : 'Update'
    },
    getCite: async function() {
      if (this.citedData[this.citedYear] !== undefined) {
        this.setCiteRange()
        this.updateCiteGraph()
        this.updateCiteTable()
        this.toggleCitationLock()
        return
      }

      let result
      if (this.isSeries) {
        result = await SeriesAPI.citations(this.seriesId, this.citedYear)
      } else {
        result = await JournalAPI.citations(this.groupId, this.citedYear)
      }
      // if (typeof (this.citedYear) === 'number') {
      //   url = url + '&year=' + this.citedYear
      // }

      if (this.isSeries) {
        if (result.results.length === 0) {
          // No series?
          this.toggleCitationLock()
          return
        }
        this.$set(this.citedData, this.citedYear, result.results[0].citationsDetails?.citations)
      } else {
        if (result.length === 0) {
          // No journal?
          this.toggleCitationLock()
          return
        }
        this.$set(this.citedData, this.citedYear, result[0].citationsDetails?.citations)
        // this.citedData[this.citedYear] = response.data.data.journals[0].citationsDetails.citations
      }
      if (!this.renderCitations && this.citedData[this.citedYear] && this.citedData[this.citedYear].length > 0) {
        this.renderCitations = true
      }
      this.setCiteRange()
      this.updateCiteGraph()
      this.updateCiteTable()
      this.toggleCitationLock()
    },
    getCiteTop: async function() {
      if (this.citedTopData[this.citedYear] !== undefined) {
        this.updateCiteTopGraph()
        this.updateCiteTopTable()
        return
      }

      let result
      if (this.isSeries) {
        result = await SeriesAPI.topCiters(this.seriesId, this.citedYear)
      } else {
        result = await JournalAPI.topCiters(this.groupId, this.citedYear)
      }
      // if (typeof (this.citedYear) === 'number') {
      //   url = url + '&year=' + this.citedYear
      // }

      if (this.isSeries) {
        if (result.results.length === 0) {
          console.log('Could not get Cite Top')
          return
        }
        this.$set(this.citedTopData, this.citedYear, result.results[0].citationsDetails?.citers)
      } else {
        if (result.length === 0) {
          console.log('Could not get Cite Top')
          return
        }
        this.$set(this.citedTopData, this.citedYear, result[0].citationsDetails?.citers)
      }

      this.updateCiteTopGraph()
      this.updateCiteTopTable()
    },
    updateCiteGraph: function() {
      const yearText = typeof (this.citedYear) !== 'number' ? ' over <b>' + this.citedYear.toLowerCase() + '</b>.' : ' in <b>' + this.citedYear + '</b>.'
      const percentTitle = '% Self Citations made' + yearText
      const normalTitle = 'Citations made to ' + this.serialType + yearText

      this.$set(this.citeGraph.changeable, 'title', this.usePercent ? percentTitle : normalTitle)
      this.$set(this.citeGraph.changeable.axis, 'y', this.usePercent ? 'Percentage' : 'Citations')

      // Clear the old data
      this.citeGraph.changeable.x.splice(0, this.citeGraph.changeable.x.length)
      this.citeGraph.changeable.y.splice(0, this.citeGraph.changeable.y.length)
      this.citeGraph.changeable.y.push([])
      this.citeGraph.changeable.y.push([])

      let pastYear = 0
      for (const i in this.citedData[this.citedYear]) {
        if (this.citedData[this.citedYear][i].year > this.latestYear) {
          break
        }
        const year = this.citedData[this.citedYear][i].year
        if (i > 0) { // Populate empty years for the graph. Test case groupId=847
          pastYear = this.citedData[this.citedYear][i - 1].year
          while (pastYear !== year - 1) {
            pastYear = pastYear + 1
            this.citeGraph.changeable.x.push(pastYear)
            if (this.usePercent) {
              this.citeGraph.changeable.y[1].push(0)
            } else {
              this.citeGraph.changeable.y[0].push(0)
              this.citeGraph.changeable.y[1].push(0)
            }
          }
        }
        this.citeGraph.changeable.x.push(year)
        if (this.usePercent) {
          this.citeGraph.changeable.y[1].push(
            (this.citedData[this.citedYear][i].self / this.citedData[this.citedYear][i].all * 100).toFixed(1)
          )
        } else {
          this.citeGraph.changeable.y[0].push(this.citedData[this.citedYear][i].all)
          this.citeGraph.changeable.y[1].push(this.citedData[this.citedYear][i].self)
        }
        pastYear = year
      }
      while (Object.keys(this.citedData).length > 0 && pastYear <= this.latestYear - 1) {
        pastYear = pastYear + 1
        this.citeGraph.changeable.x.push(pastYear)
        if (this.usePercent) {
          this.citeGraph.changeable.y[1].push(0)
        } else {
          this.citeGraph.changeable.y[0].push(0)
          this.citeGraph.changeable.y[1].push(0)
        }
      }
    },
    updateCiteTopGraph: function() {
      const yearText = typeof (this.citedYear) !== 'number' ? ' of <b>' + this.citedYear + '</b>' : ' in <b>' + this.citedYear + '</b>'
      this.$set(this.citeTopGraph.changeable, 'title', 'Top Citing Publications' + yearText)

      // Clear the old data
      this.citeTopGraph.changeable.x.splice(0, this.citeTopGraph.changeable.x.length)
      this.citeTopGraph.changeable.y.splice(0, this.citeTopGraph.changeable.y.length)

      for (const i in this.citedTopData[this.citedYear]) {
        if (i >= 10) {
          break
        }
        this.citeTopGraph.changeable.x.push(formatName(this.citedTopData[this.citedYear][i].abbr))
        this.citeTopGraph.changeable.y.push(this.citedTopData[this.citedYear][i].papers)
      }
    },
    updateCiteTable: function() {
      // Clear the old data
      if (this.citeTable.length > 0) {
        this.citeTable.splice(0, this.citeTable.length)
      }
      this.citedYearData = this.citedYear // Update the year
      if (this.usePercent) {
        const header = [
          'Year',
          'Other<span class="d-none d-sm-inline-block">&nbsp;Citations&nbsp;</span><span class="d-inline-block d-sm-none">&nbsp;Cit.&nbsp;</span>%',
          'Self<span class="d-none d-sm-inline-block">&nbsp;Citations&nbsp;</span><span class="d-inline-block d-sm-none">&nbsp;Cit.&nbsp;</span>%',
        ]
        this.citeTable.push(header)
      } else {
        const header = [
          'Year',
          '<span class="d-none d-sm-inline-block">Citations&nbsp;</span><span class="d-inline-block d-sm-none">Cit.&nbsp;</span>by Year',
          'Self<span class="d-none d-sm-inline-block">&nbsp;Citations</span><span class="d-inline-block d-sm-none">&nbsp;Cit.</span>',
          'Total<span class="d-none d-sm-inline-block">&nbsp;Publications</span><span class="d-inline-block d-sm-none">&nbsp;Pubs.</span>',
          '% of Pub. Cited',
        ]
        // if (!this.isRefList) {
        //   header = header.slice(0, 2).concat(header.slice(3))
        // }
        this.citeTable.push(header)
      }

      for (const i in this.citedData[this.citedYear]) {
        const year = this.citedData[this.citedYear][i].year
        if (year > this.latestYear) {
          break
        }
        const row = []
        row.push(year)

        if (this.usePercent) {
          row.push(
            formatNumber(((this.citedData[this.citedYear][i].all - this.citedData[this.citedYear][i].self) / this.citedData[this.citedYear][i].all * 100).toFixed(1)),
          )
          row.push(
            formatNumber((this.citedData[this.citedYear][i].self / this.citedData[this.citedYear][i].all * 100).toFixed(1))
          )
        } else {
          let query = '('
          if (this.isSeries) {
            query += `csp:${this.seriesId} `
          } else {
            query += `cgp:${this.groupId} `
          }
          query += `cy:${year}) `
          if (typeof (this.citedYear) === 'number') {
            query += `y:${this.citedYear} `
          }

          row.push(
            {
              text: formatNumber(this.citedData[this.citedYear][i].all),
              // url: this.pubUrl + this.pubQueryString.cited + this.pubQueryString.group + this.groupId, // plus year // groupId instead of series id?
              // url: URLGen.citations(requestInfo),
              to: {
                name: 'PublicationsSearch',
                query: {
                  query: query,
                },
              },
              target: 'citesearch',
              attr: {
                title: 'Publications published in ' + year + ' cited ' + (this.citedYear === 'All Time' ? 'over ' : 'in ') + this.citedYearData,
              },
            }
          )
          row.push(this.citedData[this.citedYear][i].self)

          query = `y:${year} `
          if (this.isSeries) {
            query += `si:${this.seriesId} `
          } else {
            query += `gi:${this.groupId} `
          }

          row.push(
            {
              text: formatNumber(this.citedData[this.citedYear][i].publications),
              // url: this.pubUrl + this.pubQueryString.group + this.groupId, // plus year // groupId instead of series id?
              // url: URLGen.publications(requestInfo),
              to: {
                name: 'PublicationsSearch',
                query: {
                  query: query,
                },
              },
              target: 'pubsearch',
              attr: {
                title: 'Publications in ' + year,
              },
            }
          )
          row.push((this.citedData[this.citedYear][i].publicationsCited / this.citedData[this.citedYear][i].publications * 100).toFixed(1))
        }
        this.citeTable.splice(1, 0, row)
      }
    },
    updateCiteTopTable: function() {
      if (this.citeTopTable.length > 0) {
        this.citeTopTable.splice(0, this.citeTopTable.length)
      }
      this.citeTopTable.push(
        ['Publication', 'Citations']
      )

      for (const i in this.citedTopData[this.citedYear]) {
        if (i >= 10) {
          break
        }

        const citerQuery = {}
        if (this.citedTopData[this.citedYear][i].citerType === 'jour_group') {
          citerQuery.groupId = this.citedTopData[this.citedYear][i].id
        } else {
          citerQuery.seriesId = this.citedTopData[this.citedYear][i].id
        }
        // console.log('Citations Route')
        // console.log(this.$route)
        // const test = this.$router.currentRoute
        // test.query = citerQuery
        // console.log(test)
        // console.log(this.$router.resolve({ name: 'SerialProfile', query: citerQuery }))
        this.citeTopTable.push(
          [
            {
              text: this.citedTopData[this.citedYear][i].abbr,
              // to: {
              //   name: 'SerialProfile',
              //   query: citerQuery,
              // },
              url: this.$router.resolve({
                name: 'SerialProfile',
                query: citerQuery,
              }).href,
              target: '_self',
              attr: {
                title: 'View profile for ' + this.citedTopData[this.citedYear][i].abbr,
                class: 'min-accessibility-spacing',
              },
            },
            formatNumber(this.citedTopData[this.citedYear][i].papers),
          ]
        )
      }
    },
  },
}
</script>
